<script setup lang="ts">
import dayjs from 'dayjs'
import { computed, ref, watch } from 'vue'
import { usePageStore } from '@voix/store/pageStore'
import { useBookingWidgetStore } from '@/store/bookingWidgetStore'

interface Props {
  debug: boolean
  modelValue: {
    mode: 'resort-only' | 'resort-flight'
    from: string
    destination: any
    dateRange: {
      start: string | Date
      end: string | Date
    }
    rooms: [
      {
        id: string
        adults: number
        children: number
        childrenAges: number[]
      },
    ]
  }
  error: {
    title: string
    message: string
  }
  mbrAgentData: {
    iata: string
    name: string
    email: string
  }
  preferredHotelCode: string
  disabledDates: string[]
  rate: string
  promo: string
  coupon?: string
  group: string
  currency: string
  mn: string
  showAgentFields: boolean
  discounts: {
    coupon: string | null
    promo: string | null
    rate: string | null
  }
}

const props = withDefaults(defineProps<Props>(), {
  debug: false,
  modelValue: () => ({
    mode: 'resort-only',
    from: '',
    destination: null,
    dateRange: {
      start: '',
      end: '',
    },
    rooms: [
      {
        id: 'room-1',
        adults: 2,
        children: 0,
        childrenAges: [],
      },
    ],
  }),
  error: () => ({
    title: '',
    message: '',
  }),
  mbrAgentData: () => ({
    iata: '',
    name: '',
    email: '',
  }),
  preferredHotelCode: '',
  disabledDates: () => [],
  rate: '',
  promo: '',
  coupon: '',
  group: '',
  currency: 'USD',
  mn: '',
  showAgentFields: false,
  discounts: () => ({
    coupon: null,
    promo: null,
    rate: null,
  }),
})

const emit = defineEmits(['input', 'clearError', 'setError'])

// Accent color that comes in from the inline booking slice
const accentColor: { value: string } = inject('accentColor') as { value: string }

const currentPage = computed(() => {
  const pageStore = usePageStore()
  return pageStore.currentPage
})

// Local Booking Variable
const localBooking = computed({
  get: () => props.modelValue,
  set: newValue => emit('input', newValue),
})

const action = computed(() => {
  if (props.modelValue?.destination?.synxis_url)
    return props.modelValue?.destination?.synxis_url

  if (
    props.modelValue.destination?.id !== 35
    && props.modelValue.destination?.id !== 36
    && props.modelValue.destination?.id !== 38
    && props.modelValue.destination?.id !== 43
    && props.modelValue.destination?.id !== 44
  )
    return 'https://be.synxis.com/'

  return 'https://be-p2.synxis.com/'
})
const showLoading = ref(false)

const configcode = ref('PlayaWebsite')
const themecode = ref('PlayaWebsite')

const departureDate = computed(() => {
  return dayjs(props.modelValue.dateRange.start).format('YYYY-MM-DD')
})

const returnDate = computed(() => {
  return dayjs(props.modelValue.dateRange.end).format('YYYY-MM-DD')
})

const numberOfAdults = computed(() => {
  let adults = ''
  for (let i = 0; i < props.modelValue.rooms.length; i++) {
    const room = props.modelValue.rooms[i]
    if (i > 0)
      adults += ','

    adults += room.adults.toString()
  }
  return adults
})

const numberOfChildren = computed(() => {
  let children = ''
  for (let i = 0; i < props.modelValue.rooms.length; i++) {
    const room = props.modelValue.rooms[i]
    if (i > 0)
      children += ','

    children += room.children.toString()
  }
  return children
})

const childrenAgesForForm = computed(() => {
  let ages = ''
  for (let i = 0; i < props.modelValue.rooms.length; i++) {
    const room = props.modelValue.rooms[i]
    if (i > 0)
      ages += ','

    if (room.childrenAges.length) {
      // join the array with a pipe in between the ages
      ages += room.childrenAges.join('|')
    }
  }
  return ages
})

const locale = computed(() => {
  if (currentPage.value?.language_code !== 'en')
    return 'es-MX'

  return 'en-US'
})

const chain = computed(() => {
  return props.modelValue.destination?.booking_settings?.chain
})

const gaclientid = computed(() => {
  if (typeof window === 'undefined')
    return ''

  let id = ''

  // don't want to break when ga is not available
  if (window?.ga === undefined)
    return id

  try {
    id = window.ga.getByName('Playa_Resorts').get('clientId')
  }
  catch (err) {
    console.error('GA.getByName() failed', err)
  }
  return id
})

const childrenValid = computed(() => {
  for (let i = 0; i < props.modelValue.rooms.length; i++) {
    const room = props.modelValue.rooms[i]
    for (let j = 0; j < room.children; j++) {
      const age = room.childrenAges[j]
      if (!age || age < 0 || age > 17)
        return false
    }
  }
  return true
})

function validateForSynaxis() {
  const error: {
    title: string | null
    message: string | null
  } = {
    title: null,
    message: null,
  }
  return new Promise((resolve, reject) => {
    if (
      currentPage.value?.site_id === 9
      && props.mbrAgentData.iata.length < 8
    ) {
      error.title = 'IATA Required'
      error.message
                        = 'Your IATA number should contain 8 digits. Please re-enter your IATA number.'
      reject(error)
    }

    if (!props.modelValue.destination) {
      error.title = 'Destination Required '
      error.message
                        = 'Destination required for flight booking. Please select an destination.'
      reject(error)
    }
    if (!departureDate.value || !returnDate.value) {
      error.title = 'Travel Dates Required '
      error.message
                        = 'Travel Dates required for flight booking. Please select travel dates.'
      reject(error)
    }

    const bookStart = props.modelValue.dateRange.start
    const bookEnd = props.modelValue.dateRange.end

    if (dayjs(bookStart).isSame(bookEnd)) {
      error.title = 'Invalid Check-out Date'
      error.message
                        = 'You have selected a date range that contains a sold out date. Please change your selections to continue'

      setTimeout(() => {
        localBooking.value.dateRange.end = dayjs(
          props.modelValue.dateRange.start,
        )
          .add(1, 'day')
          .toDate()
      }, 500)
      showLoading.value = false
      reject(error)
    }

    if (childrenValid.value === false) {
      error.title = 'Children Ages Required '
      error.message
                        = 'Please enter the ages of all children in your party.'
      reject(error)
    }

    resolve(true)
  })
}

const synaxisForm: Ref<HTMLFormElement | null> = ref(null)

function submitToSynaxis() {
  validateForSynaxis()
    .then(() => {
      emit('clearError')
      showLoading.value = true
      synaxisForm.value?.submit()
    })
    .catch((error) => {
      emit('setError', error.title, error.message)
    })
}

const showTPCFields = computed(() => {
  // the playa collection site
  if (currentPage.value)
    return currentPage.value.site_id === 12

  return false
})

const bookingWidgetStore = useBookingWidgetStore()
const promoCodes = computed(() => {
  return bookingWidgetStore.promoCodes
})
const promoCodeWasSetBySlice = ref(false)

const localRate = ref(props.rate)
const localPromo = ref(props.promo)
const localCoupon = ref(props.coupon)
const localCurrency = ref(props.currency)

function attemptToSetPromoCodeForResort() {
  const resortPromoCodes = bookingWidgetStore.resortPromoCodes

  if (!promoCodeWasSetBySlice.value && resortPromoCodes) {
    const newPromoCode = resortPromoCodes.find(
      element =>
        element.resort_id === props.modelValue.destination?.id,
    )
    if (typeof newPromoCode !== 'undefined' && newPromoCode) {
      switch (newPromoCode.type) {
        case 'rate':
          localRate.value = newPromoCode.value
          break
        case 'promo':
          localPromo.value = newPromoCode.value
          break
        case 'coupon':
          localCoupon.value = newPromoCode.value
          break
      }
      if (newPromoCode.currency)
        localCurrency.value = newPromoCode.currency
    }
  }
  else {
    if (currentPage.value?.site_id === 12) {
      if (
        localRate.value.startsWith('tpc')
        && props.modelValue.destination?.id > 34
        && props.modelValue.destination?.id < 37
      )
        localRate.value = localRate.value.replace('tpc', 'spc')

      else if (promoCodes.value.rate)
        localRate.value = promoCodes.value.rate
    }
  }
}

watchEffect(() => {
  attemptToSetPromoCodeForResort()
})

attemptToSetPromoCodeForResort()

if (promoCodes.value?.configcode)
  configcode.value = promoCodes.value.configcode

if (promoCodes.value.promo) {
  localPromo.value = promoCodes.value.promo
  promoCodeWasSetBySlice.value = true
}
if (promoCodes.value.coupon) {
  localCoupon.value = promoCodes.value.coupon
  promoCodeWasSetBySlice.value = true
}
if (promoCodes.value.rate) {
  localRate.value = promoCodes.value.rate
  promoCodeWasSetBySlice.value = true
}

const localGroup = ref(props.group)
if (promoCodes.value.group) {
  localGroup.value = promoCodes.value.group
  promoCodeWasSetBySlice.value = true
}
if (promoCodes.value.configcode)
  configcode.value = promoCodes.value.configcode

if (promoCodes.value.themecode)
  themecode.value = promoCodes.value.themecode

if (promoCodes.value.currency)
  localCurrency.value = promoCodes.value.currency

if (props.discounts.rate) {
  localRate.value = props.discounts.rate
  promoCodeWasSetBySlice.value = true
}

watch(promoCodes, async (newCodes) => {
  if (newCodes.promo) {
    localPromo.value = newCodes.promo
    promoCodeWasSetBySlice.value = true
  }
  if (newCodes.coupon) {
    localCoupon.value = newCodes.coupon
    promoCodeWasSetBySlice.value = true
  }
  if (newCodes.rate) {
    localRate.value = newCodes.rate
    promoCodeWasSetBySlice.value = true
  }
  if (newCodes.group) {
    localGroup.value = newCodes.group
    promoCodeWasSetBySlice.value = true
  }
})
</script>

<template>
  <div class="text-center clear-both">
    <form
      ref="synaxisForm"
      :action="action"
      method="GET"
      @submit.prevent="validateForSynaxis()"
    >
      <template v-if="props.showAgentFields">
        <input
          type="hidden"
          name="agencyid"
          :value="mbrAgentData.iata"
        >
        <input
          type="hidden"
          name="agent"
          :value="`${mbrAgentData.name},${mbrAgentData.iata}`"
        >
        <input type="hidden" name="configcode" value="TravelAgent">
        <input type="hidden" name="themecode" value="TravelAgent">
      </template>
      <template v-else-if="showTPCFields">
        <input type="hidden" name="configcode" value="TPC">
        <input type="hidden" name="themecode" value="TPC">
      </template>
      <template v-else>
        <input type="hidden" name="configcode" :value="configcode">
        <input type="hidden" name="themecode" :value="themecode">
      </template>

      <input type="hidden" name="adult" :value="numberOfAdults">
      <input type="hidden" name="child" :value="numberOfChildren">
      <input
        type="hidden"
        name="childages"
        :value="childrenAgesForForm"
      >
      <input type="hidden" name="arrive" :value="departureDate">
      <input type="hidden" name="depart" :value="returnDate">
      <input type="hidden" name="hotel" :value="preferredHotelCode">
      <input
        type="hidden"
        name="rooms"
        :value="props.modelValue.rooms.length"
      >
      <input type="hidden" name="rate" :value="localRate">
      <input type="hidden" name="promo" :value="promo">
      <input type="hidden" name="group" :value="localGroup">
      <input type="hidden" name="level" value="hotel">
      <input type="hidden" name="local" value="en-US">
      <input type="hidden" name="locale" :value="locale">
      <input type="hidden" name="chain" :value="chain">
      <input type="hidden" name="currency" :value="currency">
      <input type="hidden" name="gaclientid" :value="gaclientid">
      <input v-if="props.mn" type="hidden" name="mn" :value="props.mn">
      <input
        v-if="currentPage?.site_id === 14"
        type="hidden"
        name="src"
        value="SBE"
      >
      <button
        type="submit"
        class=" text-white px-6 py-3 uppercase text-sm font-medium"
        :style="{
          backgroundColor: accentColor.value,
        }"
        @click.prevent="submitToSynaxis"
      >
        <span v-if="showLoading">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4 inline-block animate-spin mr-1"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
            />
          </svg>

          Searching
        </span>
        <span v-else class="whitespace-nowrap"> {{ $t('book-now') }} </span>
      </button>
    </form>

    <div
      v-if="props.debug"
      class="lg:absolute lg:top-0 lg:right-0 flex flex-col space-y-6 mt-32 bg-playa-dark-blue rounded p-4 text-white text-center"
    >
      <div class="text-lg font-bold">
        Synaxis Submit
      </div>
      <div class="grid grid-cols-6 gap-x-4 gap-y-1 text-xs">
        <div class="col-span-3 text-right">
          Mode:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ value.mode }}
        </div>
        <div class="col-span-3 text-right">
          Destination:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ value.destination?.name }}
        </div>
        <div class="col-span-3 text-right">
          Adults Only:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{
            value.destination?.booking_settings?.adults_only
              ? 'Yes'
              : 'No'
          }}
        </div>
        <div class="col-span-3 text-right">
          Max Adults:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ value.destination?.booking_settings?.max_adults }}
        </div>
        <div class="col-span-3 text-right">
          Max Children:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ value.destination?.booking_settings?.max_children }}
        </div>
        <div class="col-span-3 text-right">
          Children Valid:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ childrenValid }}
        </div>
        <div class="col-span-3 text-right">
          Max Total:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ value.destination?.booking_settings?.max_total }}
        </div>
        <div class="col-span-3 text-right">
          Min Date:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ value.destination?.booking_settings?.min_booking_date }}
        </div>
        <div class="col-span-3 text-right">
          Max Date:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ value.destination?.booking_settings?.max_booking_date }}
        </div>
        <div class="col-span-3 text-right">
          Max Days Out:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ value.destination?.booking_settings?.max_days_out }}
        </div>
        <div class="col-span-3 text-right">
          Reservhotel Enabled:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{
            value.destination?.booking_settings?.reservhotel_enabled
              ? 'Yes'
              : 'No'
          }}
        </div>
        <div class="col-span-3 text-right">
          Reservhotel Number:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{
            value.destination?.booking_settings
              ?.reservhotel_hotel_number
          }}
        </div>
        <div class="col-span-3 text-right">
          Flights enabled:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{
            value.destination?.booking_settings?.flights_enabled
              ? 'Yes'
              : 'No'
          }}
        </div>
      </div>
      <div class="grid grid-cols-6 gap-x-4 gap-y-1 text-xs">
        <div class="col-span-3 text-right">
          Agent IATA:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ mbrAgentData.iata }}
        </div>
        <div class="col-span-3 text-right">
          Agent:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ `${mbrAgentData.name},${mbrAgentData.iata}` }}
        </div>
      </div>
      <div class="grid grid-cols-6 gap-x-4 gap-y-1 text-xs">
        <div class="col-span-3 text-right">
          Config Code:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ configcode }}
        </div>
        <div class="col-span-3 text-right">
          Theme Code:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ themecode }}
        </div>
      </div>
      <div class="grid grid-cols-6 gap-x-4 gap-y-1 text-xs">
        <div class="col-span-3 text-right">
          Number of Adults:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ numberOfAdults }}
        </div>
        <div class="col-span-3 text-right">
          Number of Children:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ numberOfChildren }}
        </div>
        <div class="col-span-3 text-right">
          Children Ages:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ childrenAgesForForm }}
        </div>
        <div class="col-span-3 text-right">
          Arrive:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ departureDate }}
        </div>
        <div class="col-span-3 text-right">
          Depart:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ returnDate }}
        </div>
        <div class="col-span-3 text-right">
          Hotel:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ preferredHotelCode }}
        </div>
        <div class="col-span-3 text-right">
          Rooms:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ props.modelValue.rooms.length }}
        </div>
        <div class="col-span-3 text-right">
          Rate:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ localRate }}
        </div>
        <div class="col-span-3 text-right">
          Promo:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ localPromo }}
        </div>
        <div class="col-span-3 text-right">
          Group:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ localGroup }}
        </div>
        <div class="col-span-3 text-right">
          Locale:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ locale }}
        </div>
        <div class="col-span-3 text-right">
          Chain:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ chain }}
        </div>
        <div class="col-span-3 text-right">
          Currency:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ localCurrency }}
        </div>
        <div class="col-span-3 text-right">
          gaclientid:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ gaclientid }}
        </div>
        <div class="col-span-3 text-right">
          mn:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ props.mn }}
        </div>
        <div class="col-span-3 text-right">
          SBE?:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ currentPage?.site_id === 14 ? 'Yes' : 'No' }}
        </div>
      </div>
    </div>
  </div>
</template>
